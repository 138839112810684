// .modal-backdrop{
//     display: none;
// }
.modal.bottom {
            // activeer onderstaande styles om navigeren binnen de website mogelijk te maken als de modal open is
                // top: inherit;
                // bottom: 0;
                // height: inherit;

	// .modal-dialog {
	// 	transform: translate3d(0%, 0, 0);
	// 	position: fixed;
	// 	margin: auto;
	// 	width: 100%;
	// 	max-width: 100%;
	// 	height: auto;
	// 	box-shadow: 0 -5px 15px rgba($color: $color2, $alpha: 0.5);
	// }
    // @include media-breakpoint-down(sm) {
    //     .modal-dialog{
    //         position: relative;
    //     }
    // }
	.modal-content {
		border-radius: 0;
		border: none;
		height: auto;
		overflow-y: auto;
        color: $secondary;
        width: 100%;
        .container{
            padding: 0;
        }
	}
	.modal-header {
        background-color: transparent;
		padding: 15px 30px;
        font-size: 1.1rem;
        font-weight: bold;
	}
	.modal-body {
        background-color: rgba(#ffffff, 0.6);
		padding: 15px 30px 30px;
	}
    .js-collapse{
        &.active{
            display: none;
        }
    }
    .cookieOptions{
        max-height: 0;
        transition: max-height .2s ease-out;
        overflow: hidden;
        label{
            font-weight: bold; 
            font-size: 1.1rem;
            color: $color1;
        }
        span{
            display: block;
            margin-left: calc(1.5rem + 3px);
        }
    }
    .custom-control{
        margin-left: 3px;
    }
    .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
        color: #fff;
        border-color: $steunkleur;
        background-color: $steunkleur; 
    }
    .custom-control-input:checked~.custom-control-label:before {
        color: #fff;
        border-color: $steunkleur;
        background-color: $steunkleur;
    }
}
.modal.bottom.fade {
	.modal-dialog {
		bottom: -100%;
		transition: opacity 0.3s linear, bottom 0.3s ease-out;
		bottom: -100%;
	}
}
.modal.bottom.fade.show {
	.modal-dialog {
		bottom: 0;
	}
}