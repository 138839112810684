.navbar{
    padding: 0;
}
.p-nav {
    flex-direction:column;
    align-items: flex-end;
    &.p-nav--top{
        align-items: flex-end; // right alignment Minornav - nog proper te zetten door te verplaatsen naar header
    }
    &__top{
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 0.1rem;
                a {
                    background-color: $white;
                    border-radius: 3px;
                    padding: 0.3rem 0.8rem;
                    font-family: 'Nunito', sans-serif!important; // 800
                    font-weight: 800;
                    font-size: 1.4rem;
                    color: $color1;
                    text-decoration: none;
                    line-height: normal;
                    transition: background-color .15s ease-in-out;
                    &:hover {
                        background-color: $gray-100;
                        color: $steunkleur;
                    }
                }
                &:last-child{
                    a{
                        background-color: $steunkleurlight;
                        color: $white;
                        border-radius: 5rem;
                        padding: 0.5rem 1.2rem !important;
                        &:hover{
                            // background-color: $color1;
                            background-color: $steunkleur;
                            text-decoration: none;
                        }
                        &:after {
                            content: url('../images/check-white.png');
                            vertical-align: middle;
                            margin-left: 0.5rem;
                        }
                    }
                }
                &.active {
                    a {
                        color: $steunkleur;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    &__lang{
        // margin-top: -3rem;
        // margin-right: 1rem;
        margin-bottom: 1.5rem;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        li{
            margin: 0 0.1rem;
            a {
                padding: 0.3rem 0.1rem;
                font-family: 'Nunito', sans-serif!important; // 800
                font-weight: 800;
                // font-size: 0.9rem;
                color: $gray-500;
                text-decoration: none;
                transition: background-color .15s ease-in-out;
                &:hover {
                    background-color: $gray-100;
                    color: $steunkleur;
                }
            }
            &.active {
                a {
                    color: $steunkleur;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .navbar{
        .p-nav {
            &__top{
                ul{
                    li{
                        a{
                            padding: 0.3rem 0.45rem;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(md) { 
    .navbar{
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 999;
        justify-content: space-between;
        padding: .5rem 2rem !important;
        background-color: $white;
		box-shadow: 0 10px 10px rgba($gray-500, 0.2);
        .navbar-toggler{
            padding-right: 0;
        }
        .navbar-toggler-icon{
            position: relative;
            background-image: none;
            display: inline-block;
            height: 3px;
            width: 25px;
            background: $color1;
            transition: background 0.3s 0.3s;
            &:before{
                position: absolute;
                display: inline-block;
                height: 3px;
                width: 25px;
                background: $color1;
                top: -8px;
                content: '';
                right: 0;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
            &:after{
                position: absolute;
                display: inline-block;
                height: 3px;
                width: 25px;
                background: $color1;
                top: 8px;
                content: '';
                right: 0;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
        }
        .navbar-toggler{
            border: 0;
            &:focus{
                outline: none !important;
            }
            &.active{
                .navbar-toggler-icon{
                    background: transparent;
                    &:before{
                        transform: translateX(4px) translateY(-3px) rotate(45deg);
                    }
                    &:after{
                        transform: translateX(4px) translateY(-2px) rotate(-45deg);
                    }
                }
            }
        }
        .navbar-collapse{
            overflow: scroll;
        }
        .p-nav {
            &__top{
                margin-top: 0 !important;
                margin-bottom: 0.5rem;
                ul{
                    flex-basis: 100%;
                    li{
                        margin: 0 ;
                        a{
                            display: inline-block;
                        }
                        &:last-child{
                            margin-left: auto;
                            a{
                                // display: block;
                                text-align: center;
                            }
                        }
                    }
                }

                li{
                    &:first-child a{
                        padding-left: 0;
                    }
                }
            }
            &__lang{
                margin-bottom: 0.5rem;
                padding: 0;
                justify-content: end;
                li{
                    margin: 0 0.1rem;
                    a {
                        padding: 0.3rem 0.3rem;
                        font-size: 1rem !important;
                    }
                    &:last-child{
                        a{
                            padding-right: 0;
                        }
                    }

                }
            }
        }
        &:focus{
            outline: none !important;
        }
    }
}
@include media-breakpoint-only(xs) {
    .navbar{
        padding: 0rem 1rem !important;
        .navbar-brand{
            order: 1;
        }
        .navbar-toggler{
            order: 2;
        }
        .p-nav {
            order: 4;
            display: flex;
            align-items: center;
            &__top{
                margin-top: 1rem;
                margin-bottom: 2rem;
                padding-bottom: 1rem;
                // border-bottom: 1px solid $gray-400;
                ul{ order: 2;}
                .p-nav__lang{ order: 1;}

                flex-direction: column;
                justify-content: center;
                width: 100%;
                ul{
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 2rem;
                    li{
                        margin: 0.2rem auto;
                        width: 100%;
                        border-bottom: 1px solid $gray-400;
                        a{
                            display: inline-block;
                            padding: .45rem 0!important;
                            // padding: 0.5rem 0.75rem !important;
                            // background-color: $gray-200;
                        }
                        &:first-child{
                            margin-left: 0;
                            a{
                                padding-left: 0 !important;
                            }
                        }
                        &:last-child{
                            margin-top: 2rem;
                            border-bottom: none !important;
                            a{
                                display: block;
                                border-radius: 5rem;
                                padding: 0.9rem 1.6rem !important;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            &__lang{
                display: flex;
                flex-direction: row !important;
                flex-wrap: nowrap;
                border-left: none;
                padding-left: 0 !important;
                margin-left: auto !important;
                li{
                    width: auto !important;
                    margin: 0.2rem !important;
                }
            }
        }
    }
}