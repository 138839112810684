header{
	background-color: $white;
	position: fixed;
	top: 0;
	z-index: 9;
	width: 100%;
	.container{
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		transition: margin .15s ease-in-out;
	}
	.navbar-brand{
		img, svg{
			transition: all .15s ease-in-out;
			width: 14rem;
		}
	}
	.p-nav__top ul li:last-child a{
		transition: all 0.2s ease-in-out;
	}
	@include media-breakpoint-up(md) {
		&.shrink{
			box-shadow: 0 5px 5px rgba($color: #999, $alpha: 0.15);
			.navbar-brand{
				transform: scale(0.8);
				margin-left: -10px;
				margin-bottom: -5px;
				img{
					width: 14rem !important;
					margin-top: -1rem;
					margin-bottom: -.5rem;
				}
			}
			.container{
				margin-top: .5rem;
				margin-bottom: .85rem;
			}
			.p-nav__lang{
				margin-bottom: 0.5rem;
			}
			.p-nav__top ul li a{
				font-size: 1.2rem;
				transform: scale(0.9);
			}
			.p-nav__top ul li:last-child a{
				font-size: 1.2rem;
				padding: 0.25rem 0.9rem !important;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.container{
			margin-top: 1.0rem;
			margin-bottom: 1.8rem;
		}
		.navbar-brand{
			img, svg{
				width: 12rem;
			}
		}
		&.shrink{
			box-shadow: 0 5px 5px rgba($color: #999, $alpha: 0.15);
			.navbar-brand{
				img{
					width: 10rem !important;
					margin-top: -.5rem;
					margin-bottom: -0.25rem;
				}
			}
			.container{
				margin-top: .5rem;
				margin-bottom: .85rem;
			}
		}
	}
	@include media-breakpoint-down(md) {
		// position: static;
		// top: 0;
		// width: 100%;
		// .container{
		// 	padding-top: 0;
		// 	padding-bottom: 0;
		// }
	}
	@include media-breakpoint-only(xs) {
		.navbar-brand{
			img{
				width: 9rem;
			}
		}
	}
	
}
@media only print {
    header {
        position: inherit;
    }
	main, #main{
		margin-top: 0 !important;
	}
	.btn{
		display: none;
	}
}