.hero{
    position: relative;
    .caption{
        position: absolute;
        bottom:3rem;
        width: 100%;
        // padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
        h1, h2{    
            background-color: rgba($dark,.8);
            padding: .5rem 2rem;
            border-radius: 0 3rem 3rem 0;
            color: $white !important;
            font-size: 2.0rem;            
        }
    }
    @include media-breakpoint-only(lg) { 
        .caption{
            bottom:2rem;
            h1, h2{    
                background-color: rgba($dark,.8);
                padding: .3rem 2rem;
                // border-radius: 3rem;
                color: $white !important;
                font-size: 1.8rem;            
            }
        }
    }
    @include media-breakpoint-down(md) {
        .caption{ 
            position: relative;
            bottom: inherit;
            border-bottom: 1px solid $gray-200;
            margin-bottom: 1rem;
            h1, h2{
                margin: 0;
                background-color: $white;
                padding: .5rem 2rem;
                color: $color1 !important;
                font-size: 2.20rem;
                line-height: 100%;
                text-align: center;          
            }
        }
    }
    @include media-breakpoint-only(xs) { 

    }
}