footer{
    // background-color: $gray-200;
    border-top: 1px solid $gray-400;
    margin-top: 5rem;
    font-size: 1.0rem;
    .container{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
    .logo{
        display: block;
        // margin-bottom: 1rem;
        margin-right: 2rem;
        img{
            width: 15rem;
        }
    }
    .nunito a{
        font-family: 'Nunito', sans-serif!important; // 800
        font-weight: 800;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    @include media-breakpoint-only(xs){}
}


