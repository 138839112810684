/*   @include media-breakpoint-down(md) {
    .navbar-collapse {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }
  } */
  @include media-breakpoint-down(md) {
    .navbar-collapse {
      max-height: 0;
      overflow: hidden !important;
      transition: max-height 0.2s ease-out;
    }
  }
  @include media-breakpoint-down(md) {
    .navbar-filter.navbar-collapse{
      max-height: none;
      overflow: visible;
    }
  }
  @include media-breakpoint-only(xs) {
    .navbar-filter.navbar-collapse{
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }
  }