
// FORMS
.form{
    label{
        color: $color1;
        font-weight: 500;
        font-size: 0.9rem;
    }
}
.form-group{
    margin-bottom: 1.5rem;
}
input[type=text],
input[type=email],
input[type=password],
textarea,
select{
    border: none;
    border-radius: 0 !important;
    border: none !important;
    background-color: #F4F5F4 !important;
    padding: .375rem .75rem; 
    &:focus{
        outline: none; box-shadow: none;
        background-color: #F4F5F4;  
    }
}
.form-control:focus{
    background-color: #F4F5F4;  
}

.c-form__label{
    font-weight: 500;
    color: $color1;
}

.navbar{
    input[type=text],
    input[type=email],
    input[type=password],
    textarea,
    select{
        border: none;
        border-radius: 0 !important;
        border: 1px solid $gray-300 !important;
        background-color: $white !important;
        padding: .375rem .75rem; 
        &:focus{
            outline: none; box-shadow: none;
            background-color: $white;  
            box-shadow: none;
        }
    }
    .form-control:focus{
        background-color: $white;  
    }
}


//////////////////////////////
.custom-select{
    background: no-repeat 98% center url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjNzM3QjdEIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    &:hover{
        cursor: pointer;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
}


////////////////// FORM FLOATING LABELS
.form-floating{
    position: relative;
    > .form-control {
        padding: 1rem 1rem;
    }
    > .form-control, > .form-select {
        height: calc(3.5rem + 2px);
        line-height: 1.25;
    }
    > .form-control, > .form-select {
        height: calc(3.5rem + 2px);
        line-height: 1.25;
    }
    > .form-control:focus, > .form-control:not(:placeholder-shown) {
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;
    }
    > .form-control::-webkit-input-placeholder {
        color: transparent;
      }
      
      > .form-control::-moz-placeholder {
        color: transparent;
      }
      
      > .form-control:-ms-input-placeholder {
        color: transparent;
      }
      
      > .form-control::-ms-input-placeholder {
        color: transparent;
      }
      
      > .form-control::placeholder {
        color: transparent;
      }
    // label
    > label {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 1rem 1rem;
        pointer-events: none;
        border: 1px solid transparent;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    }
    > .form-control:focus ~ label, > .form-control:not(:placeholder-shown) ~ label, > .form-select ~ label {
        opacity: 0.65;
        -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
}