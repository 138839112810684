.c-accordion {
	// background-color: $white;
	width: 100%;
	display: block;
	padding-bottom: 0.5rem;
	// &:last-child h4{
	// 	border:none;
	// }
}
.c-accordion__title {
	position: relative;
	cursor: pointer;
	transition: all 0.3s ease-out;
	&:before {
		content: '';
		width: 14px;
		height: 2px;
		background: $gray-600;
		position: absolute;
		top: calc(50% - 1px);
		right: 1rem;
		display: block;
		transition: all 0.3s;
	  }
	  &:after {
		content: '';
		width: 2px;
		height: 14px;
		background: $gray-600;
		position: absolute;
		top: calc(50% - 7px);
		right: calc(1rem + 6px);
		display: block;
		transition: all 0.3s;
	  }
	h4 {
		margin-bottom: 0;
		padding: 1rem 2rem 1rem 0;
		transition: all 0.3s ease-out;
		// font-family: 'Open Sans', sans-serif!important; // 400 800
		font-size: 1.2rem;
		font-weight: 600;
		color: $color1 !important;
		// background-color: $gray-200;
		border-bottom: 1px solid $gray-300;
		// border-radius:  1rem;
	}
	&.c-accordion-active { 
		h4 {
			color: $steunkleur;
		}
		&:after {
			transform: rotate(90deg) translateX(-1px);
			background: $steunkleur;
		}
		&:before {
			transform: rotate(180deg);
			background: $steunkleur;
		}
	}
}
.c-accordion__content {
	// background-color: $gray-200;
	// margin-left: 2rem;
	padding: 1rem 2rem 0;
	// border-left: 1px solid $gray-300;
	display: none;
	// font-size: 1.1rem;
}
