// $color1:$gray-700;
$color1: #464C5C;
$color2: $gray-500;
$color3:#8ECADC; // orange
$color4:#F1F5F9; // licht grijs

$white: #ffffff;
$light:#F7F7FB; // licht grijs
$dark: #333333; // dark grey

.color1{ color:$color1!important;}
.color2{ color:$color2!important;}
.color3{ color:$color3!important;}
.color4{ color:$color4!important;}
.white{ color:white!important;}

.bgcolor1{ background-image: none!important; background:$color1!important; color:white; h2, h3, a{color:white;}}
.bgcolor2{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor3{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor4{ background-image: none!important; background:$color4!important; }
.bgwhite{ background-image: none!important; background:white!important; }


// EQUEST STYLES
// $primary: #6ab017;
// $primary: #17a2b8; 
$steunkleur: var(--steunkleur);
$steunkleurlight: var(--steunkleurlight);
$steunkleurdark: var(--steunkleurdark);
// $secondary: #86BA10;
// $green: #86BA10;
$graylight: #e9ecef;
$bg-steunkleur: $steunkleur!important;
$bg-steunkleurlight: $steunkleurlight!important;
$text-steunkleur: $steunkleur!important; 
$text-secondary: $secondary!important; 

.text-danger{ color: darkorange!important; } 
.text-primary{ color: $primary!important; }
.text-secondary{ color: $secondary!important; }
.text-white{
	p, h1, h2, h3, h4, a, label, .text-muted { color:white!important; }
} 
.bg-green{ background: $green!important; }
.bg-graylight{ background: $graylight!important; }
.bg-steunkleur{ background: $steunkleur!important; }
.bg-steunkleurlight{ background: $steunkleurlight!important; }
.bg-secondary{ background: $secondary!important; }
.btn-primary{ background: $primary!important; border:0!important; &:hover{background: darken($primary,10%)!important; color:white!important;} }
.btn-secondary{ background: $secondary!important; border:0!important; &:hover{background: darken($secondary,10%)!important; color:white!important;} }

img{
	max-width: 100%;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
}

// VARIA

.b-radius-5{
	border-radius: 5px;
	img{
		border-radius: 5px;
	}
}
// @include media-breakpoint-up(md) {}
// @include media-breakpoint-up(lg) {}