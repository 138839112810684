@media (min-width: 1440px) {
    .container{
        max-width: 1400px;
    }
}
@include media-breakpoint-down(md) {
    .row{
        .image{
            order: 2;
        }
    }
}

// main{
//     padding-top: 2rem;
//     padding-bottom: 2rem;
// }

// TEMPLATE CODE FOR MEDIA QUERIES
//@include media-breakpoint-up(xs) {}
//@include media-breakpoint-up(sm) {}
//@include media-breakpoint-up(md) {}
//@include media-breakpoint-up(lg) {}
//@include media-breakpoint-up(xl) {}


//@include media-breakpoint-down(xs) {}
//@include media-breakpoint-down(sm) {}
//@include media-breakpoint-down(md) {}
//@include media-breakpoint-down(lg) {}
// No media query necessary for xl breakpoint as it has no upper bound on its width


//@include media-breakpoint-only(xs) {}
//@include media-breakpoint-only(sm) {}
//@include media-breakpoint-only(md) {}
//@include media-breakpoint-only(lg) {}
//@include media-breakpoint-only(xl) {}
