// BUTTONS
.btn{
    border-radius: 0;
    // padding: .6rem .9rem;
    padding: 0.5rem 0.75rem;
    font-family: 'Nunito', sans-serif!important; // 800
    font-weight: 400;
    font-size: 1.1rem;
    text-decoration: none;
    // &:hover{
    //     color: $color2;
    // }
    &.btn-lg{
        // font-size: inherit;
        font-size: 1.4rem;
        font-weight: 800;
        padding: .8rem .75rem;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &.btn-check{
        padding: .4rem .75rem;
        font-size: 1.4rem;
        border-radius: 5rem;
        padding: 0.5rem 1.2rem !important;
        &:after {
            position: relative;
            content: url('../images/check-white.png');
            vertical-align: middle;
            margin-left: 0.5rem;
        }
    }
}
.btn-theme{
    border-color: $steunkleur !important;
    background-color: $steunkleur !important;
    color: white ;
    &:hover{
        color: white ;
        border-color: $steunkleurlight !important;
        background-color: $steunkleurlight !important;
        text-decoration: none !important;
    }
}
.btn-dark{
    border-color: $steunkleurdark;
    background-color: $steunkleurdark;
    color: white ;
    &:hover{
        color: white ;
        border-color: $color1;
        background-color: $color1;
        text-decoration: none !important;
    }
}
@include media-breakpoint-only(xs) {
    .btn-angle-right{
        align-items: initial;
        &:after{
            margin-top: 1px;
        }
    }
}