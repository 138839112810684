html, body{
	font-family: 'Open Sans', sans-serif!important; // 400 800
	color: #666!important;
    font-weight: 400;
	font-size: 1.1rem;
	line-height: normal;
    text-rendering: optimizeLegibility;
}
h1, h2, h3, h4, h5{
	font-family: 'Nunito', sans-serif!important; // 800
	font-weight: 800;
	// color: $primary!important;
	color: $steunkleur!important;
}
h1, .h1{
    margin-bottom: 2rem;
    font-size: 3.0rem;
    line-height: normal;
}
h2, .h2{
    margin-top: 2.0rem;
    font-size: 2.0em;
}
main h1{
    padding-top: 2.0rem;
}
.jumbotron{
    background-color: $white;
    h1, .h1{
        font-size: 5rem;
        line-height: normal;
    }
    h2, .h2{
        margin-top: 0;
        font-size: 3.0rem;
        margin-bottom: 1.5rem;
        color: $steunkleur!important;
    }
}

ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    li {
        counter-increment: my-awesome-counter;
        position: relative;
        margin-bottom: .5rem;
        ul, ol{
            margin-top: .5rem;
        }
    }
    > li{
        margin-bottom: 1.5rem;
    }
    > li:before {
        content: counter(my-awesome-counter);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        --size: 32px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: -4px;
        background-color: $steunkleur;
        color: $white;
        border-radius: 50%;
    }
}

.lead{
    font-size: 1.4rem;
    p{
        font-size: 1.4rem;
    }
}
@include media-breakpoint-down(lg) {
    h1, .h1{
        margin-bottom: 1rem;
        font-size: 2.5rem;
        line-height: 100%;
    }
    h2, .h2{
        margin-top: 1rem;
        font-size: 1.8em !important;
    }
    .lead{
        font-size: 1.2rem;
        p{
            font-size: 1.2rem;
        }
    }
}
a{
    color: $steunkleur;
    &:not(.btn){
        text-decoration: underline;
    }
    &:hover{
        text-decoration: underline;
    }
    &.text-muted{
        color:$gray-500;
        font-size: 0.95rem;
    }
}

// CUSTOM UL WITH CHECK MARKS
.list-item-checkmark {
    padding: 0;
    // background-color: $gray-200;
    // padding: 1rem;
    li {
        display: flex;
        margin-bottom: .5rem;
        padding-bottom: .5rem;
        border-bottom: 1px solid $gray-300;
        // font-size: 1.1rem;
        font-weight: 500;
        // font-family: 'Nunito', sans-serif!important; // 800
        // font-weight: 800;
        &:before {
            content: url('../images/check.png');
            vertical-align: middle;
            margin-right: 0.5rem;
            margin-top: -0.15rem;
            line-height: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}


// CUSTOM BREADCRUMBS
.breadcrumb{
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    background-color: transparent;
    margin-bottom: 3rem;
    padding-left: 0;
    padding-right: 0;
    ul{
        display: inline-flex;
        margin: 0;
        padding: 0;
        list-style: none;
        a{
            &:after{
                content: '/';
                display: inline-block;
                margin: 0 .5rem;
                height: 10px;
                width: 10px;
            }
            &:hover{
                text-decoration: underline;
            }
        }
    }
    @include media-breakpoint-only(xs) {
        display: none;
    }
}

