.card-deck{
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    .card{
        margin: 0 1rem;
    }
    &.cards-per-3{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(33.33% - 2rem);
            margin-bottom: 0;
        }
    }
    &.cards-per-2{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(50% - 2rem);
            margin-bottom: 0;
        }
    }
}
@include media-breakpoint-down(md) {
    .card-deck{
        margin-left: 0;
        margin-right: 0; 
        flex-wrap: wrap;
        flex-direction: column;
        &.cards-per-3, &.cards-per-2{
            .card{
                margin-bottom: 2rem;
            }
        }
        .card{    
            flex-basis: 100%;
            max-width: none !important;
            margin: 0;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    } 
}

.card{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    flex: 1;
    margin-bottom: 1.5rem;
    padding:0;
    border: none;
    background-color: $gray-200;
    overflow: hidden;
    .card-body{
        padding: 2rem;
    }
    .image img{
        transition: all 0.2s ease;
    }
    &:hover{
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    }
    &.bg-steunkleur{
        &:hover{
            // background: lighten($primary,2%) !important;
            background-color: $steunkleurlight !important;
        }
    }
    .stretched-link{        
        text-decoration: none;
        font-weight: 700;
        color: #575757 ;
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            pointer-events: auto;
            content: "";
            background-color: transparent;
            order: 2;
        }
        &:hover{
            color: $color2;
            text-decoration: none;
            &:before{
                content: url('../../assets/images/angle-right-blue.svg');
            }
        }
    }
    h2{
        margin-top: 0;
    }
    .image{
        padding: 0;
        overflow: hidden;
        img{
            border-radius: 15px 15px 0 0;
        }
    }
}
@include media-breakpoint-only(xs) {
    flex-direction: column;
    .card{
        flex-basis: 100%;
        max-width: none !important; 
        margin-bottom: 1.5rem;
    }
}
